<template>
    <public-layout>
        <introduction id="introduction" />
        <features id="features" />
        <pricing id="pricing" />
        <world-map />

        <div class="mt-16">
            <div class="w-4/5 container text-center items-center justify-self-center p-4 bg-white rounded-lg shadow-lg">
                <div class="m-4 text-2xl">
                    Que esperas para comenzar?
                </div>
                <div class="w-full justify-center flex flex-row">
                    <sa-button @click="$router.push({ name: 'register.index' })">Pruebalo gratis</sa-button>
                </div>
            </div>
        </div>
    </public-layout>
</template>
<script>
export default {
    name: 'LandingPremium',
    components: {
        PublicLayout: () => import('@/components/layouts/PublicLayout'),
        Introduction: () => import('@/views/public/landing/components/Introduction'),
        Features: () => import('@/views/public/landing/components/Features'),
        Pricing: () => import('@/views/public/landing/components/Pricing'),
        WorldMap: () => import('@/views/public/landing/components/WorldMap'),
    }
}
</script>
<style>
.huge-font-size {
    font-size: 10.5rem;
}
.huge-margin-bottom {
    margin-bottom: 950px;
}
.massive-margin-bottom {
    margin-bottom: 1350px;
}
</style>